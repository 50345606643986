<template>
	<div>
		<headerbox :logo="logo" :namNum="navindex"></headerbox>
		<div class="back">
			<img src="../../assets/eventtheme/back44.png" alt="">
		</div>
		<div style="background: #F8FDFF;">
			<div class="dh" v-if="columntitle">
				<div>{{columntitle}}</div>
				<div> > </div>
				<div>{{title}}</div>
			</div>
			<div class="title-box">
				<div class="title">
					{{title}}
				</div>
			</div>

			<culture v-if="key == '城市文化'"></culture>
			<venueInformation v-if="key == '场馆信息'"></venueInformation>
			<giveservice v-if="key == '媒体服务指南'"></giveservice>
			<specimens v-if="key == '赛事集锦'"></specimens>
			<sportsman v-if="key == '运动员介绍'"></sportsman>
			<overview v-if="key == '城市概况'"></overview>
			<emblem v-if="key == '赛事会徽'"></emblem>
			<news v-if="key == '新闻报道'"></news>
			<schedule v-if="key == '赛事公告'"></schedule>
			<ticket v-if="key == '票务信息'"></ticket>
			<venuServices v-if="key == '赛场服务'"></venuServices>
			<medal v-if="key == '赛事奖牌'"></medal>
			<aboutus v-if="key == '关于我们'"></aboutus>
			<sponsor v-if="key == '赞助商'"></sponsor>
			<commodity v-if="key == '特许产品'"></commodity>
			<eventculture v-if="key == '赛事文化'"></eventculture>
			<service v-if="key == '班车信息'"></service>
			<hotel v-if="key == '酒店信息'"></hotel>
		</div>


		<footerbox></footerbox>
	</div>
</template>

<script>
	import headerbox from './component/header.vue'
	import footerbox from './component/footer.vue'
	import culture from './component/page/culture.vue' // 城市文化
	import venueInformation from './component/page/venueInformation.vue' // 场馆信息
	import giveservice from './component/page/giveservice.vue' // 媒体服务指南  
	import specimens from './component/page/specimens.vue' // 赛事集锦 
	import sportsman from './component/page/sportsman.vue' // 运动员介绍 
	import overview from './component/page/overview.vue' // 城市概况 
	import emblem from './component/page/emblem.vue' // 赛事会徽 
	import news from './component/page/news.vue' // 新闻报道 
	import schedule from './component/page/schedule.vue' // 赛事日程 
	import ticket from './component/page/ticket.vue' // 票务
	import venuServices from './component/page/venuServices.vue' // 赛场服务
	import medal from './component/page/medal.vue' // 赛事奖牌
	import aboutus from './component/page/aboutus.vue' // 关于我们
	import sponsor from './component/page/sponsor.vue' // 赞助商
	import commodity from './component/page/commodity.vue' // 产品明细 
	import eventculture from './component/page/eventculture.vue' // 赛事文化 
	import service from './component/page/service.vue' // 班车信息 
	import hotel from './component/page/hotel.vue' // 酒店信息 
	export default {
		name: '',
		components: {
			headerbox,
			footerbox,
			culture,
			venueInformation,
			giveservice,
			specimens,
			sportsman,
			emblem,
			news,
			schedule,
			ticket,
			overview,
			venuServices,
			medal,
			aboutus,
			sponsor,
			commodity,
			eventculture,
			service,
			hotel
		},
		data() {
			return {
				logo: '',
				key: 10,
				columntitle: '',
				title: '',
				navindex: 0
			}
		},
		created() {
			this.logo = this.$route.query.logo
			this.navindex = this.$route.query.navindex
			this.key = this.$route.query.title.trim()
			this.title = this.$route.query.title.trim()
			this.findAllColumn()
		},
		methods: {
			findAllColumn() {
				let data = {
					id: this.$route.query.navid,
					pid: this.$route.query.navpid
				}
				this.$api.findAllColumn(data).then(res => {
					if (!this.$verification(res.data.data.list)) {
						this.columntitle = res.data.data.list[0].name
					}
					this.title = res.data.data.pid.name 

					document.title = res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()

				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.back {
		height: 547px;
		width: 100%;

		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}

	.dh {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		font-size: 21px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding-top: 33px;
	}

	.title-box {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 50px;

		.title {
			width: 360px;
			height: 65px;
			background: url('../../assets/eventtheme/title3.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-size: 52px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #0091FF;
			text-align: center;
		}
	}
</style>