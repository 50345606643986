<template>
	<div class="head1">
		<!-- <img :src="homeDate.logo_url" alt="" class="logo" @click="getindex"> -->
		<div style="display: flex">
			<div class="navigation">
				<!-- <div class="piece" @click="goDingyue('',0)">
					<p :class="navindex == 0 ? 'name active' : 'name' ">首页</p>
				</div>
				<div class="piece" v-for="(item,index) in navList" :key="index" @click="goDingyue(item,index + 1)">
					<p class="name" :class="">{{item.name}}</p>
				</div> -->
				<ul id="list">
					<li v-for="(item, index) in navList" :key="index">
						<a @click="jump(item,index)"
							:class="navindex == index + 1  ? 'name active' : 'name'">{{ item.name }}</a>
						<ul v-if="item.children" :id="'u' + (index + 1)" class="erji">
							<li v-for="(subItem, subIndex) in item.children" :key="subIndex" class="lists">
								<span class="yuand"> ● </span>
								<a @click="jump(subItem,index)">{{ subItem.name }}</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div :class="distinguish ? 'searchfor-box searchfor-box-show' : 'searchfor-box'">
				<el-input placeholder="请输入内容" v-model="keywords" class="input-with-select" v-if="distinguish">
					<el-button slot="append" icon="el-icon-search" @click="getsearch"></el-button>
				</el-input>
				<div class="icon" @click="getclose" v-if="distinguish">
					<i class="el-icon-circle-close"></i>
				</div>
			</div>

			<img src="../../../assets/eventtheme/searchfor.png" alt=""  :class="distinguish ? 'searchfor searchfor-show' : 'searchfor'" @click="getmasking">
			<div class="qeh" @click="butchange">EN</div>
		</div>

		<div class="return" @click="getindex">
			<!-- <div class="wz">返回</br>首页</div> -->
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {},
		props: {
			logo: {
				type: String,
				default: ''
			},
			namNum: {
				type: [String, Number],
				default: ''
			},
		},
		data() {
			return {
				navindex: this.namNum,
				navList: [],
				distinguish: false,
				keywords: '',
				homeDate: {},
			}
		},
		created() {
			this.getNav()
			this.findStationById()
		},
		mounted() {
			// rem px 换算
			(function flexible(window, document) {
				var docEl = document.documentElement
				var dpr = window.devicePixelRatio || 1

				function setBodyFontSize() {
					if (document.body) {
						document.body.style.fontSize = (15 * dpr) + 'px'
					} else {
						document.addEventListener('DOMContentLoaded', setBodyFontSize)
					}
				}
				setBodyFontSize();

				function setRemUnit() {
					var rem = (docEl.clientWidth / 2032) * 100
					docEl.style.fontSize = rem + 'px'
				}
				setRemUnit()
				window.addEventListener('resize', setRemUnit)
				window.addEventListener('pageshow', function(e) {
					if (e.persisted) {
						setRemUnit()
					}
				})
				if (dpr >= 2) {
					var fakeBody = document.createElement('body')
					var testElement = document.createElement('div')
					testElement.style.border = '.5px solid transparent'
					fakeBody.appendChild(testElement)
					docEl.appendChild(fakeBody)
					if (testElement.offsetHeight === 1) {
						docEl.classList.add('hairlines')
					}
					docEl.removeChild(fakeBody)
				}
			}(window, document))
		},
		methods: {
			findStationById() {
				this.$api.findStationById(this.$route.query.id).then(res => {
					this.homeDate = res.data.data
				})
			},
			jump(item, index) {
				if (item.id == '899' || item.id == '892') {

				} else if(item.id == 0){
					this.$router.push({
						path: '/international/index',
						query: {
							id: '1005016',
							navindex: 1,
							title: '国际赛事',
							refresh: new Date().getTime()
						}
					})
				}else {
					this.navindex = Number(index) + 1
					this.$router.push({
						path: '/international/layout',
						query: {
							id: this.$route.query.id,
							navpid: item.parentId,
							navid: item.id,
							navindex: this.navindex,
							title: item.name,
							refresh: new Date().getTime()
						}
					})
				}
			},
			getNav() {
				this.$api.getNav().then(res => {
					this.navList = res.data.data
					let arr = {name: '首页', id: '0'}
					this.navList.unshift(arr)
				})
			},
			getindex() {
				this.$router.push({
					path: '/'
				})
				this.navindex = ''
			},
			getmasking() {
				this.distinguish = true
			},
			getclose() {
				this.distinguish = false
			},
			getsearch() {
				this.$router.push({
					path: '/international/searchfor',
					query: {
						id: this.$route.query.id,
						refresh: new Date().getTime(),
						title: '搜索',
						keywords: this.keywords
					}
				})
			},
			butchange() {
				let routeData = this.$router.resolve({
					path: '/internationalEn/index',
					query: {
						id: '1005017',
						refresh: new Date().getTime(),
						title: 'figure skating'
					}
				});
				window.open(routeData.href, '_self');
			}
		}
	}
</script>

<style scoped lang="scss">
	body {
		margin: 0;
		padding: 0;
	}

	.head1 {
		height: 1.27rem !important;
		display: flex;
		justify-content: center;

		.logo {
			width: 1.91rem;
			height: 1.14rem;
			margin-top: 0.38rem;
			margin-left: 2rem;
		}

		.navigation {
			display: flex;
			align-items: flex-end;
			margin-bottom: 0.34rem;
			flex-shrink: 0;
			margin-left: 0.77rem;

			#list {
				display: block;
				margin: 0px;
				padding: 0px;
				z-index: 9999;
				display: flex;
			}

			#list>li {
				float: left;
				cursor: pointer;
				margin-right: 0.05rem;
			}

			#list>li>ul {
				display: none;
				float: none;
				font-size: 15px;
				padding-left: 0px;
				background: linear-gradient(179deg, #B8E0FF 0%, #FFFFFF 99%);
				border-radius: 0.17rem;
				color: #0091FF;
				margin-top: -0.42rem;
				padding-top: 0.4rem;
				position: absolute;
				z-index: -1;
			}

			#list>li>ul .name {
				display: none;
				float: none;
				font-size: 15px;
				padding-left: 0px;
				background: linear-gradient(179deg, #B8E0FF 0%, #FFFFFF 99%);
				border-radius: 23px;
				color: #0091FF;
			}

			#list>li>a {
				font-size: 0.25rem !important;
				font-family: MicrosoftYaHei;
				font-weight: 400;
				color: #000000;
				position: relative;
				text-decoration: none;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0.23rem;
				padding: 0.05rem 0;
				width: 1.5rem;
			}

			#list>li>ul>li {
				width: 1.5rem;
				font-size: 0.21rem;
				text-align: center;
				cursor: pointer;
				position: relative;
				z-index: 9999;
				align-items: center;
			}

			.lists {
				display: flex;
				justify-content: center;

				.yuand {
					margin-right: 5px;
					display: none;
					margin-top: -6px
				}
			}

			#list>li:hover .erji {
				display: block;

				a {
					color: #0091FF;
				}
			}

			#list>li:hover .name {
				background: #0091FF;
				color: #fff;
			}


			#list>li>ul>li>a {
				font-size: 0.18rem;
				font-family: MicrosoftYaHei;
				font-weight: 400;
				color: #fff;
				position: relative;
				text-decoration: none;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px 0;
				padding-bottom: 15px;
			}

			#list>li>ul>.lists:hover .yuand {
				display: block;
			}

			#list ul li:hover ul {
				display: block;
			}

			ul {
				list-style-type: none;
			}

			.active {
				background: #0091FF;
				color: #fff !important;
			}
		}

		.searchfor-box {
			display: flex;
			align-items: end;
			margin-bottom: 44px;
			margin-left: 0.34rem;

		}

		.searchfor {
			width: 0.2rem;
			height: 0.2rem;
			cursor: pointer;
			margin-top: 0.62rem;
			flex-shrink: 0;
		}
		.searchfor-show{
			margin-left: 0.34rem;
		}

		.qeh {
			font-size: 0.2rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #0091FF;
			margin-left: 0.23rem;
			cursor: pointer;
			position: relative;
			margin-top: 0.60rem;
			width: 50px;
		}

		.searchfor-box-show {
			position: absolute;
			width: 100%;
			height: 1.27rem;
			top: 0;
			left: 0;
			z-index: 9999;
			overflow: hidden;
			background: rgba(120, 120, 120, 0.7);
			display: -webkit-flex;
			margin-left: 0px;
		}

		.icon {
			position: absolute;
			top: 0.55rem;
			right: 0.6rem;

			.el-icon-circle-close {
				font-size: 0.32rem;
				color: #fff;
			}
		}
	}

	.input-with-select {
		width: 2.5rem;
		height: 0.4rem;
		position: absolute;
		top: 0.5rem;
		right: 1rem;

		::v-deep .el-input__inner {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			border: none;
			height: 0.4rem;
		}

		::v-deep .el-input-group__append {
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
			font-size: 0.22rem;
			color: #0091FF;
		}
	}

	.return {
		right: 42px;
		width: 128px;
		height: 253px;
		background: #D5ECFF;
		border-radius: 15px;
		cursor: pointer;
		z-index: 999999;

		top: 50%;
		transform: translateY(-50%);
		position: fixed;

		background: url('../../../assets/eventtheme/fh.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.wz {
			font-size: 28px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000509;
			line-height: 38px;
			text-align: center;
			margin-top: 202px;
		}
	}
</style>