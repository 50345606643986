<template>
	<div style="display: flex;margin: 59px auto 0 auto;justify-content: center;">
		<!-- <div class="banner-box">
			<div class="banner" v-for="(item,index) in date" :key="index" :id="'card-' + index">
				<img :src="item.file" alt="">
				<div class="nr">
					<div class="name">{{item.name}}</div>
					<div class="xm">{{item.item}}</div>
					<div class="gj">国籍：{{item.address}}</div>
				</div>
			</div>
		</div> -->

		<!-- <div class="right-sort">
			<div v-for="(item, index) in list" :key="index" class="sort-item"
				:class="index == activeIndex ? 'sort-item-active' : ''" @click="changeActive(index)">
				{{item}}
			</div>
		</div> -->

		<div class="infocont">
			<img class="infocont_log" src="../../../../assets/eventtheme/yundy.png" alt="">
			<div class="infocont_info">
				<div class="infocont_infoitem">
					<div class="infocont_infotxt color_blue">
						<h3 class="subtitle">运动员信息</h3>
						<p>您可以在<a class="txt_undex" target="_blank"
								href="https://results.isu.org/events/fsevent00117444.htm">此处</a>获取所有运动员信息
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				date: [],
				list: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
					'U', 'V', 'W', 'K', 'Y', 'Z', '#'
				],
				activeIndex: ''
			}
		},
		created() {
			// this.sportsmanList()
		},
		methods: {
			changeActive(index) {
				this.activeIndex = index;

				this.$nextTick(() => {
					let CardId = document.querySelector('#card-' + index);

					if (CardId) {
						window.scrollTo({
							'top': CardId.offsetTop,
							'behavior': 'smooth'
						});
					}
				});
			},
			sportsmanList() {
				let data = {
					stationId: this.$route.query.id
				}
				this.$api.sportsmanList(data).then(res => {
					this.date = res.data.data
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		width: 1242px;
		// margin: 59px auto 0 auto;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 59px;

		.banner {
			width: 394px;
			height: 241px;
			display: flex;
			background: url('../../../../assets/eventtheme/hbback4.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 30px;
			margin-bottom: 41px;

			img {
				width: 143px;
				height: 194px;
				background: #A8E8FF;
				border-radius: 15px;
				margin-top: 24px;
				margin-left: 22px;
				flex-shrink: 0;
			}

			.nr {
				margin-left: 24px;

				.name {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 40px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					width: 191px;

				}

				.xm {
					font-size: 23px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 49px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					width: 191px;
				}

				.gj {
					font-size: 23px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 20px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					width: 191px;
				}
			}
		}

		.banner:nth-child(3n) {
			margin-right: 0;
		}
	}

	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding-bottom: 73px;
	}

	.right-sort {
		cursor: pointer;
		margin-left: 87px;
		padding-bottom: 59px;
	}

	.sort-item-active {
		color: #0091FF;
	}


	.infocont {
		width: 12rem;
		display: flex;
		align-items: center;
		padding-bottom: 50px;
		margin-left: 200px;
	}

	.infocont_log {
		width: 500px;
		height: auto;
	}

	.infocont_info {
		padding-left: 10%;
		flex: 1;
	}

	.infocont_infoitem {
		width: 100%;
	}

	.color_blue {
		color: #0091FF;
	}

	.infocont_infotxt {
		padding-top: 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}
	
	.infocont_infotxt .subtitle {
	    font-weight: 600;
	    padding-bottom: 4px;
		font-size: 25px;
	}
	
	.infocont_infotxt{
		p{
			font-size: 20px;
			margin-top: 20px;
		}
	}
	
	.infocont_infotxt a {
	    color: #0091FF;
	}
	.txt_undex {
	    text-decoration: underline;
	}
	a {
	    text-decoration: none;
	    text-transform: none;
	    color: #333;
	}
</style>