<template>
	<div>
		<div class="banner-box">
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img1.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛场限定帆布包</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img2.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事官方徽章</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img3.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛场限定亚克力冰箱贴</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img4.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛场限定手机气囊支架</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img5.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛场限定挂包钥匙扣</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img6.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事官方手提袋</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img7.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事官方保温杯</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img8.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事官方礼品盖毯</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img9.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事全能滑雪旅行包</div>
				</div>
			</div>
			<div class="banner">
				<div class="img">
					<img src="../../../../assets/commodity/img10.png" alt="">
				</div>
				<div class="br-box">
					<div class="title">赛事官方礼品茶具套装</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		created() {},
		methods: {}
	}
</script>

<style scoped lang="scss">
	.banner-box {
		width: 1200px;
		margin: 0 auto;
		margin-top: 75px;
		display: flex;
		flex-wrap: wrap;

		.banner {
			width: 281px;
			background: #fff;
			margin-right: 24px;
			margin-bottom: 44px;
			cursor: pointer;

			.img {
				width: 281px;
				height: 280px;
				background: #fff;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: scale-down;
				}
			}

			.br-box {
				padding: 0 19px;
				display: flex;
				justify-content: center;

				.title {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin: 21px 0;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					line-height: 30px;
				}
			}
		}

		.banner:nth-child(4n) {
			margin-right: 0;
		}
	}

	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		margin: 0 auto;
		padding-bottom: 73px;
	}
</style>